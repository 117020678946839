.privacyTag{
  color: black;
  cursor: pointer;
  text-decoration: none;
}
.privacyTag:hover{
  text-decoration: underline;
}

.footerBGImg {
  width: 20%;
}

@media (min-width: 1560px) and (max-width: 2560px) {
  .mobileFooterHeight {
      height: 690px;
    }
  
    .footerLinkText button {
      font-size: 3.3rem;
    }

    .footerBGImg{
      width: 20%;
    }

    .whiteLogoImg{
      margin-top: 20px;
      width: 13%;
    }
}

@media (min-width: 750px) and (max-width: 1190px) {
  .mobileFooterHeight{
    height: 350px;
  }
  .footerLinkText button{
    font-size: 1.2rem;
  }
    .footerBGImg {
      width: 20%;
    }
}
@media (min-width: 320px) and (max-width: 423px) {
  .mobileFooterHeight {
    height: 270px;
  }
    .footerBGImg {
      width: 50%;
    }
}

@media (min-width: 424px) and (max-width: 529px) {
  .mobileFooterHeight {
    height: 270px;
  }
    .footerBGImg {
      width: 50%;
    }
}

@media (min-width: 530px) and (max-width: 767px) {
  .mobileFooterHeight {
    height: 310px;
  }
    .footerBGImg {
      width: 50%;
    }
}

/* Add these styles to your CSS or styled-components */

.footer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stars-img {
  width: 100%;
  position: absolute;
  bottom: 2px;
  z-index: 1;
}

.footer-content {
  position: relative;
  z-index: 4;
  width: 100%;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Add your link styles here */
}

.text-texturina {
  font-size: 16px;
  /* Adjust font size as needed */
}
.footer-bg-image {
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  position: relative;
}

.spacer {
  height: 200px;
  /* Adjust the height to control spacing */
}