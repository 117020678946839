.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

input {
  -webkit-appearance: none;
  /* Disable Safari's default styles */
}

.background-paper {
  /* background-image: url('./images/BG/BG for Phase 2_Tickets_Desktop.png'); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.background-home {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/images/BG/BG+for+Phase+2_Tickets_Desktop.webp');
}
.background-home-mobile {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile/BG+Phase+2_Homepage-Lineup_Mobile+.webp');
}

.background-register-mobile {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile/BG+Phase+1_Register_Mobile.webp');
}
.background-register {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/images/BG/BG+Phase+1_Register+Page_Desktop.webp');
}

.background-about {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/images/BG/BG+Phase+2_About_Bandland_Desktop+%E2%80%93+1.webp');
}

.background-artists {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile-phase2/BG+Phase+2_Artists_Desktop.png');
}

.background-tickets {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/images/BG/BG+for+Phase+2_Tickets_Desktop.webp');
}

.background-faq {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile-phase2/BG+Phase+2_FAQ_Desktop.webp');
}

.background-about-mobile {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile-phase2/BG+Phase+2_About+Bandland.webp');
}

.background-artists-mobile {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile-phase2/BG+Phase+2_The_Artists_Mobile+_new.webp');
}

.background-tickets-mobile {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile/BG+Phase+2_Homepage-Lineup_Mobile+.webp');
}

.background-faq-mobile {
  background-image: url('https://bandland.s3.ap-south-1.amazonaws.com/mobile-phase2/BG+Phase+2_FAQ_mobile.webp');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
