.ticketBanner {
  width: 80%;
  height: 520px;
  position: relative;
  z-index: 3;
  opacity: 1;
}
.ticketContentBox {
  overflow-y: scroll;
}

/* .ticketContentBox::-webkit-scrollbar {
  width: 6px;
} */

/* Track */
/* .ticketContentBox::-webkit-scrollbar-track {
  background: #f1f1f1;
} */

/* Handle */
/* .ticketContentBox::-webkit-scrollbar-thumb {
  background: #888;
} */

/* Handle on hover */
/* .ticketContentBox::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

h1.ticketHeading {
  color: white;
  font-size: 50px;
  /* text-shadow: 4px 4px black; */
  margin: 0px;
  padding: 0px;
}

.ticketText {
  width: 412px;
  height: 180px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 0px;
  font: normal normal medium 16px/25px Texturina;
  letter-spacing: 0px;
  color: #390c06;
  opacity: 1;
  padding-bottom: 0px;
}

.ticketBannerMargin {
  margin: 10px;
}

.ticketTextBanner {
  width: 80%;
}

.ticketContent {
  font-size: 14px !important;
}
/* 
@media (min-width: 320px) and (max-width: 423px) {
  h1.ticketHeading {
    color: white;
    font-size: 18px;
    text-shadow: 2px 2px black;
    margin: 0px;
    padding: 0px;
  }

  p.ticketText {
    width: 100%;
    height: 74px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    letter-spacing: 0px;
    color: #390c06;
    opacity: 1;
    padding-bottom: 0px;
    overflow: hidden;
  }

  .ticketBanner {
    width: 256px;
    height: 220px;
  }
}

@media (min-width: 424px) and (max-width: 529px) {
  h1.ticketHeading {
    color: white;
    font-size: 20px;
    text-shadow: 2px 2px black;
    margin: 0px;
    padding: 0px;
  }

  p.ticketText {
    width: 100%;
    height: 84px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 10px;
    letter-spacing: 0px;
    color: #390c06;
    opacity: 1;
    padding-bottom: 0px;
    overflow: hidden;
  }

  .ticketBanner {
    width: 296px;
    height: 220px;
  }
} */


@media (max-width: 767px) {
  h1.ticketHeading {
    color: white;
    font-size: 18px;
    text-shadow: 2px 2px black;
    margin: 0px;
    padding: 0px;
  }

  p.ticketText {
    width: 90%;
    height: 14vh;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 20px;
    letter-spacing: 0px;
    color: #390c06;
    opacity: 1;
    padding-bottom: 0px;
    overflow: hidden;
    font-size: 15px;
  }
    .ticketTextBanner {
      width: 100%;
    }
  
    .ticketContent {
      font-size: 10px !important;
    }
    .ticketContentBox{
      overflow-y: scroll;

    }

}

/* For tablet and desktop screens */
@media (min-width: 768px) {
  h1.ticketHeading {
    color: white;
    font-size: 24px;
    text-shadow: 4px 4px black;
    margin: 0px;
    padding: 0px;
    margin-bottom: 10px;
  }

  p.ticketText {
    width: 60%;
    height: 14vh;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #390c06;
    opacity: 1;
    padding-bottom: 0px;
    overflow: hidden;
  }

    .ticketTextBanner {
      width: 90%;
    }
  
    .ticketContent {
      font-size: 10px !important;
    }

        .ticketContentBox::-webkit-scrollbar {
          width: 6px;
        }
    
        /* Track */
        /* .ticketContentBox::-webkit-scrollbar-track {
      background: #f1f1f1;
    } */
    
        /* Handle */
        .ticketContentBox::-webkit-scrollbar-thumb {
          background: #888;
        }
    
        /* Handle on hover */
        .ticketContentBox::-webkit-scrollbar-thumb:hover {
          background: #555;
        }

}

@media (min-width: 1024px) {
  h1.ticketHeading {
    color: white;
    font-size: 32px;
    text-shadow: 4px 4px black;
    /* margin-bottom: 10px; */
    margin: 0px;
    padding: 0px;
  }

  p.ticketText {
    width: 60%;
    height: 14vh;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #390c06;
    opacity: 1;
    padding-bottom: 0px;
    overflow: hidden;
  }

    .ticketTextBanner {
      width: 100%;
    }
  
    .ticketContent {
      font-size: 12px !important;
    }

}

@media (min-width: 1441px) {
  h1.ticketHeading {
    color: white;
    font-size: 42px;
    text-shadow: 4px 4px black;
    /* margin-bottom: 10px; */
    margin: 0px;
    padding: 0px;
  }

  p.ticketText {
    width: 50%;
    height: 20vh;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 25px;
    letter-spacing: 0px;
    color: #390c06;
    opacity: 1;
    padding-bottom: 0px;
    overflow: hidden;
  }

  .ticketTextBanner {
    width: 80%;
  }

  .ticketContent {
    font-size: 18px !important;
  }

}

@media (min-width: 1921px) {
  h1.ticketHeading {
    color: white;
    font-size: 72px;
    text-shadow: 4px 4px black;
    /* margin-bottom: 10px; */
    margin: 0px;
    padding: 0px;
  }

  p.ticketText {
    width: 55%;
    height: 20vh;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 34px;
    letter-spacing: 0px;
    color: #390c06;
    opacity: 1;
    padding-bottom: 0px;
    overflow: hidden;
  }

  .ticketTextBanner{
    width: 80%;
  }
  .ticketContent{
    font-size: 24px !important;
  }
}