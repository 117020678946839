.responsive-video-banner {
  width: 45%;
  height: 100%;
}

.responsive-see-you-again-banner {
  width: 55%;
  height: 100%;
}

.sliderWrapper {
  width: 70%;
  margin: auto;
}

.video-wrapper {
  position: relative;
  padding-top: 5.25%; /* Player ratio: 100 / (1280 / 720) */
  cursor: pointer;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.sliderImage {
  width: 250px;
  /* height: 280px; */
  /* aspect-ratio: 4/3; */
  object-fit: cover;
  border-radius: 15px;
}

.sliderCarouselImage {
  width: 800px;
  /* aspect-ratio: 4/3; */
  /* height: 80vh; */
  object-fit: fill;
  border-radius: 15px;
}

.video-title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: white;
  font-size: 35px;
  -webkit-text-stroke: 1px white;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
}

.two-days-stage {
  position: absolute;
  top: 0;
  left: 22%;
}

@media (max-width: 319px) {
  /* .sliderImage {
    width: 60px;
    height: 70px;
  } */
  .video-container {
    margin-top: -22px;
  }
  .sliderWrapper {
    width: 70%;
  }
  .responsive-video-banner {
    width: 70%;
    height: 100%;
  }
  .responsive-see-you-again-banner {
    width: 75%;
    height: 100%;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    margin-top: 10px;
    color: white;
    font-size: 12px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: "10%";
  }
}

@media (min-width: 320px) and (max-width: 340px) {
  .video-wrapper {
    position: relative;
    padding-top: 190px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  .sliderWrapper {
    width: 65%;
  }
  /* .sliderImage {
    width: 90px;
    height: 90px;
  } */
  .responsive-video-banner {
    width: 70%;
    height: 100%;
  }
  .responsive-see-you-again-banner {
    width: 75%;
    height: 100%;
  }
  .video-container {
    margin-top: -180px;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 10%;
  }
}

@media (min-width: 340px) and (max-width: 424px) {
  .video-wrapper {
    position: relative;
    padding-top: 190px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 90px;
    height: 110px;
  } */
  .sliderWrapper {
    width: 65%;
  }

  .responsive-video-banner {
    width: 70%;
    height: 100%;
  }
  .responsive-see-you-again-banner {
    width: 75%;
    height: 100%;
  }
  .video-container {
    margin-top: -180px;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
    letter-spacing: 1px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 10%;
  }
}

@media (min-width: 424px) and (max-width: 529px) {
  .video-wrapper {
    position: relative;
    padding-top: 80px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 80px;
    height: 130px;
  } */
  .sliderWrapper {
    width: 65%;
  }

  .video-container {
    margin-top: -70px;
  }
  .responsive-video-banner {
    width: 70%;
    height: 100%;
  }
  .responsive-see-you-again-banner {
    width: 75%;
    height: 100%;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 10%;
  }
}

@media (min-width: 530px) and (max-width: 600px) {
  .video-wrapper {
    position: relative;
    padding-top: 70px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 120px;
    height: 150px;
  } */
  .sliderWrapper {
    width: 65%;
  }

  .video-container {
    margin-top: -70px;
  }
  .responsive-video-banner {
    width: 70%;
    height: 100%;
  }
  .responsive-see-you-again-banner {
    width: 75%;
    height: 100%;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    margin-top: 10px;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 10%;
  }
}

@media (min-width: 601px) and (max-width: 767px) {
  .video-wrapper {
    position: relative;
    padding-top: 70px;
    /* Player ratio: 100 / (1280 / 720) */
  }

  /* .sliderImage {
    width: 120px;
    height: 130px;
  } */
  .sliderWrapper {
    width: 65%;
  }

  .video-container {
    margin-top: -70px;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 14px;
    margin-top: 20px;
    letter-spacing: 1px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 15%;
  }
}

/* For tablet and desktop screens */
@media (min-width: 768px) and (max-width: 930px) {
  .video-wrapper {
    position: relative;
    padding-top: 80px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 120px;
    height: 160px;
  } */
  .sliderWrapper {
    width: 65%;
  }

  .video-container {
    margin-top: -70px;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 18px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 20%;
  }
}

@media (min-width: 930px) and (max-width: 1200px) {
  .video-wrapper {
    position: relative;
    padding-top: 80px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 200px;
    height: 200px;
  } */
  .sliderWrapper {
    width: 65%;
  }

  .video-container {
    margin-top: -70px;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 22px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 20%;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .video-wrapper {
    position: relative;
    padding-top: 70px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 200px;
    height: 250px;
  } */
  .sliderWrapper {
    width: 70%;
  }

  .video-container {
    margin-top: -70px;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 28px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 22%;
  }
}

@media (min-width: 1301) and (max-width: 1440px) {
  .video-wrapper {
    position: relative;
    padding-top: 70px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 200px;
    height: 250px;
  } */

  .video-container {
    margin-top: -200px;
  }
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 28px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 22%;
  }
}

@media (min-width: 1440px) and (max-width: 1570px) {
  .video-wrapper {
    position: relative;
    padding-top: 200px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 270px;
    height: 270px;
  } */

  .video-container {
    margin-top: -200px;
  }
  /* .video-title {
    width: 300px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    color: white;
    font-size: 16px;
    margin-bottom: -5px;
    margin-top: 60px;
  } */
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 22%;
  }
}

@media (min-width: 1570px) and (max-width: 1700px) {
  .video-wrapper {
    position: relative;
    padding-top: 200px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 280px;
    height: 300px;
  } */

  .video-container {
    margin-top: -200px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 22%;
  }
}

@media (min-width: 1700px) and (max-width: 2000px) {
  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: white;
    font-size: 45px;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }
  .video-wrapper {
    position: relative;
    padding-top: 200px;
    /* Player ratio: 100 / (1280 / 720) */
  }
  /* .sliderImage {
    width: 280px;
    height: 350px;
  } */

  .video-container {
    margin-top: -200px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 22%;
  }
}

@media (min-width: 2000px) and (max-width: 2500px) {
  .video-wrapper {
    position: relative;
    padding-top: 200px;
    /* Player ratio: 100 / (1280 / 720) */
  }

  .video-title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    color: white;
    font-size: 50px;
    -webkit-text-stroke: 1px white;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
  }

  /* .sliderImage {
    width: 280px;
    height: 400px;
  } */

  .video-container {
    margin-top: -200px;
  }
  .two-days-stage {
    position: absolute;
    top: 0;
    left: 22%;
  }
}
